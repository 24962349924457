import React, { FC, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'

import {
  Button,
  ButtonSet,
  Column,
  ComboBox,
  DatePickerInput,
  Row,
  Select,
  SelectItem,
  Stack,
  TextInput,
} from '@carbon/react'
import { zodResolver } from '@hookform/resolvers/zod'
import dayjs from 'dayjs'
import { Hook } from 'flatpickr/dist/types/options'
import { observer } from 'mobx-react-lite'
import { z } from 'zod'

import { formErrors } from '@src/library/utils/constants'
import { useLoader } from '@src/library/utils/hooks'
import filterStyles from '@src/modules/filters/ui/styles/Filters.module.scss'
import { UserStore } from '@src/modules/user'

import { formatDate } from '@helpers/date'
import { useRouterSearchParams } from '@helpers/router'
import { isEqualLogin } from '@helpers/text.js'

import { $loader, $user } from '@library/providers/StoreProvider'
import HcmDatePicker from '@library/ui/datePicker/HcmDatePicker'
import FormEditLink from '@library/ui/formEditLink/FormEditLink'
import Title from '@library/ui/title/Title'
import { ModalBase, ModalBody, ModalFooter, ModalHeader } from '@library/utils/modals'
import { IModalItemCore } from '@library/utils/modals/ModalViewer.store'
import { showErrorAlert } from '@library/utils/toast'

import { personService } from '@services'
import { Onboarding } from '@services/models/person'

import { useHRPPFilter } from '../../analytic/model/hooks/useHRPPFilter'
import { FilterItem } from '../../filters/model/hooks/usePersonsFilter'
import styles from './OnboardingFormModal.module.scss'
import OnboardingTabs from './tabs/OnboardingTabs'

export type IViewType = 'close' | 'edit' | undefined

type IProps = {
  initId?: string
  viewType?: IViewType
  person?: string
  _core: IModalItemCore
}

export const ONBOARDING_CREATE_UPDATE_FROM = 'onboarding-create-or-update-form'

const createSchema = (user: UserStore) =>
  z
    .object({
      isClose: z.boolean(),
      personNickName: z.string().min(1, { message: formErrors.required }),
      startDate: z.date({
        invalid_type_error: formErrors.invalidValue,
        required_error: formErrors.required,
      }),
      endDate: z.date({
        invalid_type_error: formErrors.invalidValue,
        required_error: formErrors.required,
      }),
      dueTo: z.union([
        z.string().nullable(),
        z.date({
          invalid_type_error: formErrors.invalidValue,
          required_error: formErrors.required,
        }),
      ]),
      mentor: z.union([z.null(), z.string().min(1, { message: formErrors.required })]),
      hrpp: z.union([z.null(), z.string().min(1, { message: formErrors.required })]),
      planLink: z.string().url(),
    })
    .superRefine((values, ctx) => {
      // Проверка на isClose и dueTo
      if (values.isClose && !values.dueTo) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['dueTo'],
          message: formErrors.required,
        })
      }

      // Проверка на соответствие mentor текущему пользователю
      if (
        user.role !== 'SUPERUSER' &&
        values.mentor &&
        values.mentor.toLocaleLowerCase() ===
          user.loggedInUser.preferredUsername.toLocaleLowerCase()
      ) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['mentor'],
          message: 'Mentor не должен быть текущим пользователем',
        })
      }
    })

const OnboardingFormModal: FC<IProps> = ({ initId, viewType: _viewType, person, _core }) => {
  const searchParams = useRouterSearchParams()
  const onShowModal = () => initId && searchParams.set('id', initId)
  const onHideModal = () => initId && searchParams.remove('id')

  const [loadedData, setLoadedData] = useState<Onboarding>({})
  const [personList, setPersonsList] = useState<FilterItem[]>([])
  const { hrppsOptions, isHRPPLoading } = useHRPPFilter()

  const isNew = !initId
  const isView = !!initId
  const isClosedStatus = loadedData.dueTo
  const isCanEdit =
    $user.hasPerm('PERM_ONBOARDING_EDIT') &&
    ($user.role === 'SUPERUSER' ||
      !isEqualLogin(loadedData.personNickName!, $user.loggedInUser.preferredUsername)) &&
    !isClosedStatus
  const [viewType, setViewType] = useState<IViewType>(_viewType)
  const isEdit = isView && viewType === 'edit'
  const isClose = isView && viewType === 'close'

  /*
   * Form controller
   */
  const defaultValues = {
    isClose,
    personNickName: person || '',
    startDate: null,
    endDate: null,
    dueTo: null,
    mentor: '',
    hrpp: '',
    planLink: '',
  }

  const {
    register,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: zodResolver(createSchema($user)),
  })

  /*
   * Data loading
   */
  const isOnboardingLoading = useLoader(async () => {
    if (!isView) {
      return
    }

    const response = await personService.fetchOneOnboarding(initId!)

    if (response.isSuccess && response.data) {
      let data = response.data

      if (data.startDate) {
        data.startDate = dayjs(data.startDate).toDate()
      }

      if (data.endDate) {
        data.endDate = dayjs(data.endDate).toDate()
      }

      if (data.dueTo) {
        data.dueTo = dayjs(data.dueTo).toDate()
      }

      setLoadedData(data)

      const preparedData = _.mapValues(defaultValues, (x, key) => {
        if (_.isNil(data[key])) {
          return x
        }

        return data[key]
      })

      reset(preparedData)
    }
  })

  const isPersonsLoading = useLoader(async () => {
    const manager = $user.hasPerm('PERM_ONBOARDING_MANAGER')
      ? $user.loggedInUser.preferredUsername
      : undefined

    const response = await personService.fetch(manager, undefined, manager)

    if (response.isSuccess && response.data?.data) {
      const data = _.orderBy(response.data?.data, 'nickName', 'asc')
      const mapped = data.map((user: any) => ({
        key: [user.id, user.nickName].join('_'),
        text: user.nickName!,
        value: user.nickName!,
      }))
      setPersonsList(mapped)
    }
  })

  const isLoading = isOnboardingLoading || isPersonsLoading

  /*
   * Prepared values
   */
  const values = watch()

  /*
   * Field handlers
   */
  const onPeriodChange: Hook = (dates: any) => {
    setValue('startDate', dates[0])
    setValue('endDate', dates[1])
  }

  const onDueToChange: Hook = (dates: any) => {
    setValue('dueTo', dates[0])
  }

  /*
   * Submit handler
   */

  const canEdit = useMemo(() => {
    return {
      personNickName: isNew && !person,
      startDate: isNew,
      endDate: isNew,
      dueTo: isNew || (isCanEdit && isClose),
      mentor: isNew || (isCanEdit && isEdit),
      hrpp: isNew || (isCanEdit && isEdit),
      planLink: isNew || (isCanEdit && isEdit),
    }
  }, [isNew, isEdit, isClose, isCanEdit])

  const submitIsAvailable = _.some(canEdit, (x: any) => x)

  const onSubmit = $loader.registerHandler(ONBOARDING_CREATE_UPDATE_FROM, async (data) => {
    let payload = data

    if (payload.startDate) {
      payload.startDate = formatDate(payload.startDate, { format: 'isoDate' })
    }

    if (payload.endDate) {
      payload.endDate = formatDate(payload.endDate, { format: 'isoDate' })
    }

    if (payload.dueTo) {
      payload.dueTo = formatDate(payload.dueTo, { format: 'isoDate' })
    }

    let response

    if (isView) {
      payload.id = initId
      response = await personService.updateOnboarding(payload)
    } else {
      payload.author = $user.loggedInUser.preferredUsername
      response = await personService.createOnboarding(payload)
    }

    if (response.isSuccess) {
      _core.hide()
    } else {
      showErrorAlert(formErrors.somethingWentWrong)
    }
  })

  return (
    <ModalBase size={isView ? 'xxl' : 'md'} onShow={onShowModal} onHide={onHideModal}>
      <ModalHeader className="mb-20">
        <Title size="h2">
          {isEdit ? (
            <>Редактировать данные по адаптации</>
          ) : isClose ? (
            <>Закрыть испытательный срок</>
          ) : isView ? (
            <>
              Просмотр адаптации{' '}
              {!isLoading && isCanEdit && (
                <>
                  <small className="a" onClick={() => setViewType('edit')}>
                    Редактировать
                  </small>
                  {' | '}
                  <small className="a" onClick={() => setViewType('close')}>
                    Закрыть
                  </small>
                </>
              )}
            </>
          ) : (
            <>Добавить данные по адаптации</>
          )}
        </Title>
      </ModalHeader>
      <ModalBody isLoading={isLoading} className="pb-20">
        <Row>
          <Column lg={isView ? 8 : 16}>
            {isView && (
              <div className={styles.infoBox}>
                <Row>
                  <Column lg={7} />
                  <Column lg={9}>
                    <div className={styles.row}>
                      Автор: <strong>{loadedData.author}</strong>
                    </div>
                    <div className={styles.row}>
                      Дата создания: <strong>{formatDate(loadedData.createDate)}</strong>
                    </div>
                  </Column>
                </Row>
              </div>
            )}
            <form
              id={ONBOARDING_CREATE_UPDATE_FROM}
              noValidate={true}
              onSubmit={handleSubmit(onSubmit)}
            >
              <Stack gap={6}>
                <ComboBox
                  /* @ts-ignore */
                  id="personNickName"
                  titleText="Сотрудник"
                  items={personList}
                  itemToString={(item: any) => (item ? item.text : '')}
                  onChange={(item: any) => setValue('personNickName', item?.selectedItem?.text)}
                  selectedItem={
                    !!values.personNickName && {
                      text: values.personNickName,
                      value: values.personNickName,
                    }
                  }
                  disabled={isPersonsLoading}
                  className={filterStyles.comboBox}
                  invalid={!!errors.personNickName}
                  invalidText={<>{errors.personNickName?.message}</>}
                  readOnly={!canEdit['personNickName']}
                  downshiftProps={!canEdit['personNickName'] ? { isOpen: false } : undefined}
                />

                <HcmDatePicker
                  datePickerType="range"
                  value={[values.startDate, values.endDate]}
                  onChange={onPeriodChange}
                  readOnly={!canEdit['startDate'] || !canEdit['endDate']}
                  minDate={isNew ? dayjs().startOf('day').toISOString() : undefined}
                >
                  <DatePickerInput
                    labelText="Период C"
                    placeholder="dd.mm.yyyy"
                    id="startDate"
                    invalid={!!errors.startDate}
                    invalidText={<>{errors.startDate?.message}</>}
                    readOnly={!canEdit['startDate']}
                  />
                  <DatePickerInput
                    placeholder="dd.mm.yyyy"
                    labelText="По"
                    id="endDate"
                    invalid={!!errors.endDate}
                    invalidText={<>{errors.endDate?.message}</>}
                    readOnly={!canEdit['endDate']}
                  />
                </HcmDatePicker>

                {!isClose && (
                  <>
                    <ComboBox
                      /* @ts-ignore */
                      id="mentor"
                      titleText="Ментор"
                      items={personList}
                      itemToString={(item: any) => (item ? item.text : '')}
                      onChange={(item: any) => setValue('mentor', item?.selectedItem?.text)}
                      selectedItem={
                        !!values.mentor && {
                          text: values.mentor,
                          value: values.mentor,
                        }
                      }
                      disabled={isPersonsLoading}
                      className={filterStyles.comboBox}
                      invalid={!!errors.mentor}
                      invalidText={<>{errors.mentor?.message}</>}
                      readOnly={!canEdit['mentor']}
                      downshiftProps={!canEdit['mentor'] ? { isOpen: false } : undefined}
                    />

                    <Select
                      id="hrpp"
                      labelText="HRPP"
                      {...register('hrpp')}
                      invalid={!!errors.hrpp}
                      invalidText={<>{errors.hrpp?.message}</>}
                      readOnly={!canEdit['hrpp']}
                      disabled={isHRPPLoading}
                    >
                      {hrppsOptions?.map((user) => (
                        <SelectItem key={user.key} text={user.text} value={user.value} />
                      ))}
                    </Select>

                    <FormEditLink
                      value={values.planLink}
                      initOpen={!isView}
                      readOnly={!canEdit['planLink']}
                    >
                      <TextInput
                        id="planLink"
                        labelText="Ссылка на план адаптации"
                        {...register('planLink')}
                        invalid={!!errors.planLink}
                        invalidText={<>{errors.planLink?.message}</>}
                        readOnly={!canEdit['planLink']}
                      />
                    </FormEditLink>
                  </>
                )}
                {(isClose || isClosedStatus) && (
                  <HcmDatePicker
                    value={values.dueTo || ''}
                    onChange={onDueToChange}
                    invalid={!!errors.dueTo}
                    invalidText={<>{errors.dueTo?.message}</>}
                    readOnly={!canEdit['dueTo']}
                    minDate={
                      loadedData.startDate
                        ? dayjs(loadedData.startDate).startOf('day').toISOString()
                        : undefined
                    }
                  >
                    <DatePickerInput
                      placeholder="dd/mm/yyyy"
                      labelText="Дата закрытия испытательного срока"
                      id="dueTo"
                      invalid={!!errors.dueTo}
                      invalidText={<>{errors.dueTo?.message}</>}
                      readOnly={!canEdit['dueTo']}
                    />
                  </HcmDatePicker>
                )}
              </Stack>
            </form>
          </Column>
          {isView && (
            <Column lg={8} style={{ maxHeight: 500 }}>
              <OnboardingTabs item={loadedData} />
            </Column>
          )}
        </Row>
      </ModalBody>
      {!isLoading && submitIsAvailable && (
        <ModalFooter>
          <ButtonSet>
            <Button kind="tertiary" onClick={_core.hide}>
              Отмена
            </Button>
            <Button form={ONBOARDING_CREATE_UPDATE_FROM} type="submit">
              Сохранить
            </Button>
          </ButtonSet>
        </ModalFooter>
      )}
    </ModalBase>
  )
}

export default observer(OnboardingFormModal)
