
import { Tab, TabList, Tabs } from '@carbon/react';
import PersonCard from '@library/ui/personCard/PersonCard';
import WithLoaderWrapper from '@library/ui/withLoaderWrapper/WithLoaderWrapper';
import { useStore } from '@src/library/providers/StoreProvider';
import { useLoader } from '@src/library/utils/hooks';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { nestedRoutes } from '../model/routes';

import styles from './CabinetPage.module.scss';

const CabinetPage = () => {
  const { $cabinet, $user } = useStore();
  const nav = useNavigate();
  const { id, pageKey } = useParams();
  const nestedPaths = nestedRoutes($user.role)?.map((x) => x?.route);

  let userId: any;
  let userPageKey: any;

  if (nestedPaths.includes(id!)) {
    userId = undefined;
    userPageKey = id;
  } else {
    userId = id;
    userPageKey = pageKey;
  }

  const isLoading = useLoader(async () => {
    $cabinet.clear();
    $cabinet.SET_LOGGED_IN_LOGIN($user.loggedInUser.preferredUsername);
    $cabinet.SET_SELECTED_LOGIN(userId || $user.loggedInUser.preferredUsername);
    await $cabinet.fetch();
  }, [userId]);

  console.log($cabinet.data);
  useEffect(() => {
    const index = nestedRoutes($user.role)?.findIndex((x) => x?.route === userPageKey);
    setSelectedIndex(Math.max(index, 0));
  }, [userId, userPageKey]);

  const [selectedIndex, setSelectedIndex] = useState(0);

  const filteredRoutes = useMemo(() => {
    if (isLoading) {return [];}

    return nestedRoutes($user.role)?.filter((x) => x?.condition());
  }, [isLoading]);

  console.log($cabinet.data, 'cabinet data');

  return (
    <WithLoaderWrapper isLoading={isLoading}>
      <PersonCard data={$cabinet.data} />
      {$cabinet.canShowDetails && filteredRoutes.length && (
        <>
          <div className={classNames(styles.tabs, 'mt-20')}>
            <Tabs selectedIndex={selectedIndex} onChange={(x) => setSelectedIndex(x.selectedIndex)}>
              <TabList aria-label="cabinet-submenu" fullWidth={true} contained={true}>
                {filteredRoutes.map((tab, index) => (
                  <Tab
                    key={index}
                    onClick={() =>
                      nav(['/user-profile', userId, tab?.route].filter(Boolean).join('/'))
                    }
                  >
                    {tab?.label}
                  </Tab>
                ))}
              </TabList>
            </Tabs>
          </div>
          {filteredRoutes[selectedIndex]?.element}
        </>
      )}
    </WithLoaderWrapper>
  );
};

export default observer(CabinetPage);
